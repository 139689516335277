import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Upload, Button, Table} from 'antd';

const initialFileUploadValue = {
  url: null,
  data: {},
  filename: null,
};

function FileUpload(props) {
  const [actions] = useOutlet('actions');
  const {onFinished} = props;
  const [fileUpload, setFileUpload] = React.useState(initialFileUploadValue);

  const beforeUpload = async (file) => {
    try {
      actions.setLoading(true);
      let resp = await actions.getUploadUrlFromFile(file);
      setFileUpload({
        url: resp.url,
        data: resp.fields,
        filename: resp.expected,
      });
    } catch (err) {
      alert('Api error');
      actions.setLoading(false);
    }
  };

  const handleChange = async (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      actions.setLoading(false);
      setFileUpload(initialFileUploadValue);
      alert('Upload success!');
      if (onFinished) {
        onFinished();
      }
    }
  };

  return (
    <Upload
      name="file"
      listType="file container"
      showUploadList={false}
      action={fileUpload.url}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      data={fileUpload.data}>
      <Button type="primary">上傳檔案</Button>
    </Upload>
  );
}

function TabFiles() {
  const [actions] = useOutlet('actions');
  const [user] = useOutlet('user');
  const [updater, setUpdater] = React.useState(0);
  const [uploads, setUploads] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        actions.setLoading(true);
        setUploads((await actions.fetchAllUploads()).filenames);
      } finally {
        actions.setLoading(false);
      }
    }

    fetchData();
  }, [updater, actions]);

  function isStg() {
    const service = user.services.find((s) => s.service_name === 'jstorage');
    return service.domain.indexOf('-stg') > -1;
  }

  // const buildFullPath = (filename) =>
  //   `https://${user.client}${
  //     isStg() ? '-stg' : ''
  //   }.s3-ap-northeast-1.amazonaws.com/${filename}`;
  const buildFullPath = (filename) => filename;

  function onFinished() {
    console.log('updated');
    setUpdater(updater + 1);
  }

  return (
    <Wrapper>
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 15}}>
        <div style={{flex: 1}} />
        <FileUpload onFinished={onFinished} />
      </div>
      <Table
        dataSource={uploads}
        columns={[
          {
            title: '預覽',
            key: 'preview',
            render: (_, record) => <ImagePreview src={buildFullPath(record)} />,
          },
          {
            title: 'URL',
            key: 'name',
            render: (_, record) => <div>{buildFullPath(record)}</div>,
          },
          {
            title: '連結',
            key: 'link',
            render: (_, record) => (
              <Button
                onClick={() => {
                  window.open(buildFullPath(record), '_blank');
                }}>
                開啟
              </Button>
            ),
          },
        ]}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;
  padding: 15px;
`;

const ImagePreview = styled.img`
  width: 240px;
  height: 180px;
  object-fit: contain;
  background-color: white;
  border: 1px dashed #ccc;
  border-radius: 4px;
`;

export default TabFiles;
