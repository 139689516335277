import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import TabBar from '../../components/TabBar';
import TabUploads from './TabUploads';

const TabItems = ['UPLOADS'];

function StorageServicePage(props) {
  const [selectedTab, setSelectedTab] = React.useState(TabItems[0]);

  return (
    <Outer>
      <Wrapper>
        <TabBar
          items={TabItems}
          onItemClick={setSelectedTab}
          isItemActive={(item) => item === selectedTab}
        />
        <div className="tab-content">
          {selectedTab === TabItems[0] && <TabUploads {...props} />}
        </div>
      </Wrapper>
    </Outer>
  );
}

const Outer = styled.div`
  padding: 80px 20px 0px 20px;
  background-color: ${AppContext.Theme.lightBgColor};
  height: 100vh;
  position: relative;
`;

const Wrapper = styled.div`
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;

  & > .tab-content {
    height: calc(100% - 40px);
    overflow: auto;
  }
`;

export default StorageServicePage;
